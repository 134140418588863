import React from 'react'
import { Link } from 'gatsby'
import { shape, string } from 'prop-types'

import Layout from '../components/blog/Layout'

import { default as styles } from '../styles/404-page.module.css'

const NotFoundPage = ({ location: { pathname } }) => {
    const [lang] = pathname
        .split(`/`)
        .filter(part => part !== ``)

    return (
        <Layout pathname={pathname}>
            <div className="container">
                <article className="content" style={{ textAlign: `center` }}>
                    <h1 className={styles.contentTitle}>Error 404</h1>
                    <section style={{ marginTop: `20px` }}>
                        {lang === `fr` ? (
                            <>Nous n&apos;avons rien trouvé, <Link to={`/fr/`}>returnez à l&apos;accueil</Link> pour recommencer.</>
                        ) : null}
                        {lang === `en` ? (
                            <>Page not found, <Link to={`/en/`}>return home</Link> to start over</>
                        ) : null}
                    </section>
                </article>
            </div>
        </Layout>
    )
}

NotFoundPage.propTypes = {
    location: shape({
        pathname: string.isRequired,
    }).isRequired,
}

export default NotFoundPage
